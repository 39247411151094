<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          block
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow>
              <CCol sm="12" v-show="showBools.FiltersAreaCombo">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <FiltersAreaCombo :activeElements="activeItems" />
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
            </CRow>
            <CRow>
              <CCol xl="3">
                <label class="filter-label">FY</label>
                <FilterFY v-show="showBools.FilterFY" />
              </CCol>
              <CCol xl="3">
                <label class="filter-label">{{
                  messages.purchasesTracking.table1
                }}</label>
                <FilterMonth v-show="showBools.FilterMonth" />
              </CCol>
              <CCol xl="6">
                <label class="filter-label">{{
                  messages.purchasesTracking.table2
                }}</label>
                <FilterMonthsRange v-show="showBools.FilterMonthsRange" />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary">Mostrar</CButton>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CRow v-if="showLoader1" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showTable1" id="table1" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <CButton
          color="secondary"
          class="gtm-tag-export mb-3 d-flex ml-auto mr-3"
          @click="exportHTMLToExcel()"
        >
          {{ messages.tables.export.toUpperCase() }}&nbsp;&nbsp;
          <LogoExcel />
        </CButton>
      </CCol>
      <CCol sm="12">
        <TablePurchasesTracking
          :tableData="processedDataTable1"
          tableId="table1"
        >
          <span slot="area">{{ selectedArea }} {{ selectedArea && '-' }}</span>
          <span slot="month">{{ selectedMonth }}</span>
          <span slot="fiscalYear">{{ selectedFY }}</span>
        </TablePurchasesTracking>
      </CCol>
    </CRow>
    <CRow v-if="showLoader2" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showTable2" id="table2" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <TablePurchasesTracking
          :tableData="processedDataTable2"
          tableId="table2"
        >
          <span slot="area">{{ selectedArea }} {{ selectedArea && '-' }}</span>
          <span slot="month">{{ selectedPeriod }}</span>
          <span slot="fiscalYear">{{ selectedFY2 }}</span>
        </TablePurchasesTracking>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Api from "@/services/Api";
import ExportHTML2Excel from "@/helpers/ExportHTML2Excel";
import LogoExcel from "@/components/atoms/LogoExcel";
const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filtersAreaCombo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(
    /* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY"
  );
const FilterMonth = () =>
  import(
    /* webpackChunkName: "filterMonth" */ "@/components/molecules/FilterMonth"
  );
const FilterMonthsRange = () =>
  import(
    /* webpackChunkName: "filterMonthsRange" */ "@/components/molecules/FilterMonthsRange"
  );
const TablePurchasesTracking = () =>
  import(
    /* webpackChunkName: "tablePurchasingTrackingMonth" */ "@/components/organisms/TablePurchasesTracking"
  );

import Loader from "@/components/atoms/Loader";
import formatNum from "@/utils/formatNum";
import langStr from "@/utils/langStr";
import currentFY from "@/services/CurrentFY";
import filtersByProfile from "@/helpers/filtersByProfile";
import defaultAreaParam from "@/helpers/defaultAreaParam";
import calculatePeriod from "@/helpers/calculatePeriod";
import formatFiscalYear from "@/helpers/formatFiscalYear";
import {getConcessionName} from "@/helpers/getAreaName";

export default {
  name: "PurchasesTracking",
  components: {
    FiltersAreaCombo,
    FilterFY,
    FilterMonth,
    FilterMonthsRange,
    Loader,
    TablePurchasesTracking,
    LogoExcel,
  },
  data() {
    return {
      filtersList: null,
      activeItems: [],
      collapse: false,
      cardCollapse: true,
      isCollapsed: true,
      areaError: false,
      user: null,
      lang: null,
      messages: null,
      showLoader1: false,
      showLoader2: false,
      showTable1: false,
      showTable2: false,
      processedDataTable1: null,
      processedDataTable2: null,
      showBools: {
        FiltersAreaCombo: false,
        FilterFY: false,
        FilterMonth: false,
        FilterMonthsRange: false,
      },
      selectedArea: null,
    };
  },
  computed: {
    implicitParams() {
      const n = this.user.profileId;
      if (n >= 20 && n < 30) {
        return {
          concessionCode: this.user.concessionCode,
        };
      }
      return null;
    },
  },
  methods: {
    makeDefaultQuery() {
      currentFY().then((currentFY) => {
        const defaultArea = defaultAreaParam(this.user);
        const defaults = {};
        const areaKey = defaultArea.key;
        defaults.area = {
          countryCode: "",
          regionalCode: "",
          aspm: "",
          concessionCode: "",
        };
        defaults.area[areaKey] = defaultArea.value;
        defaults.fy = { fiscalYear: currentFY };
        defaults.month = { month: new Date().getMonth() + 1 };
        const period = calculatePeriod(currentFY);

        defaults.sinceMonth = { sinceMonth: period.start };
        defaults.upToMonth = { upToMonth: period.end };
        defaults.fy2 = { fiscalYear: period.year };

        this.makeApiQueries(defaults);
      });
    },
    submitFilterValues(event) {
      event.preventDefault();

      let params,
        validated = false;
      if (this.implicitParams) {
        params = { area: { ...this.implicitParams } };
        validated = true;
      } else {
        params = { area: { ...this.$store.getters.getFiltersAreaComboParams } };
        if (!this.$store.getters.getFiltersAreaComboParams) {
          this.areaError = true;
        } else {
          this.areaError = false;
          validated = true;
        }
      }

      if (validated) {
        const fiscalYear = this.$store.getters.getFilterFYValue;
        params.fy = { fiscalYear: fiscalYear };
        params.fy2 = { fiscalYear: fiscalYear };
        params.month = { month: this.$store.getters.getFilterMonthValue };
        params.sinceMonth = {
          sinceMonth: this.$store.getters.getFilterMonthSinceValue,
        };
        params.upToMonth = {
          upToMonth: this.$store.getters.getFilterMonthUpToValue,
        };

        this.makeApiQueries(params);
      }
    },
    makeApiQueries(params) {
      this.showLoader1 = true;
      this.showTable1 = false;
      this.showLoader2 = true;
      this.showTable2 = false;

      params.langId = { languageId: langStr.getId(this.lang) };

      const paramsTable1 = {
        ...params.langId,
        ...params.area,
        ...params.fy,
        ...params.month,
      };


      const paramsTable2 = {
        ...params.langId,
        ...params.area,
        ...params.fy2,
        ...params.sinceMonth,
        ...params.upToMonth,
      };

      const areaValue = params.area.countryCode
        ? this.messages.areaByCode[params.area.countryCode]
        : params.area.regionalCode
        ? this.messages.areaByCode[params.area.regionalCode]
        : params.area.aspm
        ? params.area.aspm
        : params.area.concessionCode
        ? Number(params.area.concessionCode)
        : "";

      this.selectedArea = isNaN(areaValue) ? areaValue : getConcessionName(areaValue);
      this.selectedMonth = this.messages.filters.months[params.month.month];
      const formattedFiscalYear1 = formatFiscalYear(params.fy.fiscalYear);
      this.selectedFY = formattedFiscalYear1;
      const formattedFiscalYear2 = formatFiscalYear(params.fy2.fiscalYear);
      this.selectedFY2 = formattedFiscalYear2;
      const sinceMonth = params.sinceMonth.sinceMonth;
      const upToMonth = params.upToMonth.upToMonth;
      this.selectedPeriod = sinceMonth === upToMonth ? 
      this.messages.filters.months[sinceMonth] : `de ${
        this.messages.filters.months[sinceMonth]
      } a ${
        this.messages.filters.months[upToMonth]
      }`;

      this.getPurchasesData(paramsTable1).then((results) => {
        this.showLoader1 = false;
        this.showTable1 = true;
        this.processedDataTable1 = this.processRawData(results);
      });
      this.getPurchasesData(paramsTable2).then((results) => {
        this.showLoader2 = false;
        this.showTable2 = true;
        this.processedDataTable2 = this.processRawData(results);
      });
    },
    getPurchasesData(params) {
      return Api.sendPost("commercial/purchasesTracing", params).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              return response.data.data;
            }
          }
        }
      );
    },
    processRawData(rawData) {
      rawData.forEach((row) => {
        row._classes = "focus";
        if (row.concept.includes("TOTAL")) row._classes = "dark-gray";
        Object.keys(row).forEach((key) => {
          if (row[key] === null) {
            return (row[key] = "");
          } else if (typeof row[key] === "number") {
            let units = "%";
            let increment = "";
            if (
              key === "targetPurchases" ||
              key === "purchases" ||
              key === "purchasesUioAverage" ||
              key === "nationalPurchasesAverage" ||
              key === "ncs"
            ) {
              units = "€";
            }
            if (
              (key === "purchasesComparative" ||
                key === "purchasesUioAverageComparative" ||
                key === "nationalPurchasesAverageComparative") &&
              row[key] > 0
            ) {
              increment = "+";
            }
            if (key === "purchasesQuantity") units = "";
            return (row[key] = `${increment}${formatNum.get(
              row[key],
              2
            )}${units}`);
          }
        });
      });
      return rawData;
    },
    // Refactor
    mountFilters(filtersNames) {
      filtersNames.forEach((elem) => {
        if (typeof elem === "string") {
          this.showBools[elem] = true;
        } else if (typeof elem === "object") {
          this.showBools.FiltersAreaCombo = true;
          this.activeItems = elem.FiltersAreaCombo;
        }
      });
    },
    exportHTMLToExcel() {
      const table1 = document.querySelector("#table1 table").cloneNode(true);
      const table1Title = document
        .querySelector("#table1 .table-title")
        .cloneNode(true);
      const table2 = document.querySelector("#table2 table").cloneNode(true);
      const table2Title = document
        .querySelector("#table2 .table-title")
        .cloneNode(true);
      const newRow = document.createElement("tr");
      table1.append(newRow, newRow.cloneNode(true)); //add 2 empty rows after table 1
      const tableToExport = document.createElement("div");
      tableToExport.append(table1Title, table1, table2Title, table2);
      ExportHTML2Excel(tableToExport, this.messages.purchasesTracking.title);
    },
  },
  created() {
    this.user = this.$store.getters.getCurrentUser;
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    this.makeDefaultQuery();
    this.filtersList = filtersByProfile(
      "purchasesTracking",
      this.user.profileId
    );
    this.mountFilters(this.filtersList);
  },
};
</script>
